<template>
  <div>
    <customer-list></customer-list>
  </div>
</template>

<script>
import CustomerList from './CustomerList.vue';

export default {
  components: {
    CustomerList,
  },

  data() {
    return {};
  },
};
</script>
